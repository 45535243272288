import PropTypes from 'prop-types';
import { Children } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

const Margin = ({ children, className }) => {
  const hasHeading = Children.count(children) > 1;

  return (
    <div
      className={twJoin(
        'my-0 mb-16 flex w-full items-center px-4 lg:px-8',
        !hasHeading && 'pt-4 lg:pt-14'
      )}
      data-global-flatbg
    >
      <div
        className={twMerge(
          'relative mx-auto w-full lg:w-screen-lg xl:w-screen-xl',
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};

Margin.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Margin;
