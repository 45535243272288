import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from 'react';

import ErrorBoundary from 'components/ErrorBoundary';

import useMediaQuery from 'hooks/useMediaQuery';
import useOffersFilters from 'hooks/useOffersFilters';
import useQueryClient from 'hooks/useQueryClient';

import {
  isInternationalOfferByOfferTags,
  isOfferCategoryIdFilterActive,
  isOfferSubcategoryIdFilterActive,
} from 'lib/offer';
import { offerQueryKeys } from 'lib/queryKeys';
import { removeHashFromUrl } from 'lib/request';
import { scrollToElement } from 'lib/utils';

import { useGlobalQueries } from 'providers/GlobalQueriesProvider';

import OffersList from './OffersList';
import OffersListHeader from './OffersListHeader';

import APP from 'constants/app';
import OFFER from 'constants/offer';

const ErrorBoundaryFallback = dynamic(() => import('./ErrorBoundaryFallback'), {
  ssr: false,
});

const ADDITIONAL_SPACE_FROM_TOP_ON_SCROLL_IN_PX = 16;
const MILLISECONDS_TO_WAIT_BEFORE_SCROLLING_TO_LAST_OFFERS = 500;

/**
 * Even that majority of props aren't utilized, we reproduces the API
 * data-structure to keep the standard and avoid unexpected behaviors.
 */
const CUSTOM_FIRST_CARD_AD = [
  {
    id: 14022025, // Campaign start date
    position: 1,
    description:
      'Os produtos que você ama pelo preço que você sempre quis! Somente para Promolovers!',
    url: '/oferta/batom-natura-por-r550-e-mais-vem-ai-mais-um-preco-dos-sonhos-1967513/',
    image: `${APP.LOCAL_IMAGE_PATH}/banner/card-banner-natura-desk.png`,
    mobileImage: `${APP.LOCAL_IMAGE_PATH}/banner/card-banner-natura-mobile.png`,
    tabletImage: null,
    bgcolor: [],
    isClosable: false,
    type: 'CARD_AD',
    storeSlug: null,
    event: null,
    height: null,
    onClick: () => {},
  },
];

export const OffersListSection = ({
  cardsAds,
  cookieIsUserLogged,
  gaEventCategory,
  serverOffers,
}) => {
  const offersListHeaderRef = useRef(null);
  const router = useRouter();
  const { isLg } = useMediaQuery();
  const queryClient = useQueryClient();
  const { activeOffersListTab } = useGlobalQueries();
  const { offersFiltersState, getOffersFilters } = useOffersFilters();

  const offers = useMemo(() => {
    const isFirstOfferExclusiveForLoggedUsers =
      serverOffers?.offers[0]?.offerUserVisibility ===
      OFFER.VISIBILITY_STATUS.LOGGED_ONLY;

    return isFirstOfferExclusiveForLoggedUsers && !cookieIsUserLogged
      ? {
          ...serverOffers,
          offers: [...serverOffers.offers.slice(1)],
        }
      : serverOffers;
  }, [cookieIsUserLogged, serverOffers]);

  const cardsAdsWithCustomFirstAd = useMemo(() => {
    const isFirstOfferExclusiveForLoggedUsers =
      serverOffers?.offers[0]?.offerUserVisibility ===
      OFFER.VISIBILITY_STATUS.LOGGED_ONLY;

    if (isFirstOfferExclusiveForLoggedUsers && cookieIsUserLogged) {
      return cardsAds;
    }

    return [
      ...CUSTOM_FIRST_CARD_AD.map((cardAd) => ({
        ...cardAd,
        gaEventAction: 'card_banner__home_offer_1967513',
      })),
      ...cardsAds.map((cardAd) => ({
        ...cardAd,
        position: cardAd.position - 1,
      })),
    ];
  }, [cookieIsUserLogged, cardsAds, serverOffers]);

  /**
   * Scroll to last offers section of the page if a valid hash is present
   * at the url
   */
  useEffect(() => {
    const interval = setInterval(() => {
      if (window.location.hash.includes(OFFER.LAST_OFFERS_HASH)) {
        scrollToElement(
          offersListHeaderRef.current?.offsetTop -
            ADDITIONAL_SPACE_FROM_TOP_ON_SCROLL_IN_PX,
          isLg
        );
        removeHashFromUrl(router);
      }
    }, MILLISECONDS_TO_WAIT_BEFORE_SCROLLING_TO_LAST_OFFERS);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const refetchOfferPagesWithFilters = async () => {
      const {
        categories,
        subcategories,
        lastAction,
        isInternationalOffersActive,
      } = getOffersFilters();

      const isOfferWithFilterActive = ({
        categoryId,
        subcategoryId,
        offerTags,
      }) =>
        isOfferCategoryIdFilterActive(categories, categoryId) ||
        isOfferSubcategoryIdFilterActive(subcategories, subcategoryId) ||
        (isInternationalOfferByOfferTags(offerTags) &&
          !isInternationalOffersActive);

      // TODO this can be improved with a future refactor
      if (lastAction === 'ADD' || !lastAction) {
        queryClient.setInfiniteQueryData(
          offerQueryKeys.list({ activeTab: activeOffersListTab.id }),
          (pages) =>
            pages.map((page) => ({
              ...page,
              offers: page.offers.filter(
                (offer) => !isOfferWithFilterActive(offer)
              ),
            }))
        );
        return;
      }

      if (lastAction === 'DELETE') {
        queryClient.refetchQueries(
          offerQueryKeys.list({ activeTab: activeOffersListTab.id })
        );
      }
    };

    refetchOfferPagesWithFilters();
  }, [queryClient, offersFiltersState]);

  return (
    <div className="col-span-4 w-full flex-col md:col-span-12 xl:col-span-12">
      <section className="col-span-4 flex flex-col gap-y-4 md:col-span-12">
        <OffersListHeader
          ref={offersListHeaderRef}
          gaEventCategory={gaEventCategory}
        />
        <ErrorBoundary fallback={ErrorBoundaryFallback}>
          <OffersList
            cardsAds={cardsAdsWithCustomFirstAd}
            cookieIsUserLogged={cookieIsUserLogged}
            gaEventCategory={gaEventCategory}
            offersFilters={offersFiltersState}
            offersListHeaderRef={offersListHeaderRef}
            serverOffers={offers}
          />
        </ErrorBoundary>
      </section>
    </div>
  );
};

OffersListSection.propTypes = {
  cardsAds: PropTypes.arrayOf(
    PropTypes.shape({
      bgcolor: PropTypes.arrayOf(PropTypes.string),
      description: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      image: PropTypes.string.isRequired,
      isClosable: PropTypes.bool.isRequired,
      mobileImage: PropTypes.string.isRequired,
      position: PropTypes.number.isRequired,
      storeSlug: PropTypes.string,
      type: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  cookieIsUserLogged: PropTypes.bool.isRequired,
  serverOffers: PropTypes.shape({
    after: PropTypes.string.isRequired,
    before: PropTypes.number,
    offers: PropTypes.arrayOf(
      PropTypes.shape({
        categoryId: PropTypes.number,
        categoryName: PropTypes.string,
        categorySlug: PropTypes.string,
        key: PropTypes.string.isRequired,
        offerComments: PropTypes.number.isRequired,
        offerId: PropTypes.number.isRequired,
        offerIsHighlight: PropTypes.bool.isRequired,
        ratings: PropTypes.shape().isRequired,
        offerOldPrice: PropTypes.number,
        offerPhoto: PropTypes.string.isRequired,
        offerPrice: PropTypes.number.isRequired,
        offerPriceType: PropTypes.string.isRequired,
        offerPublished: PropTypes.string.isRequired,
        offerSlug: PropTypes.string.isRequired,
        offerStatusName: PropTypes.string.isRequired,
        offerUserVisibility: PropTypes.string.isRequired,
        offerTags: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
          })
        ),
        offerTitle: PropTypes.string.isRequired,
        storeDomain: PropTypes.string.isRequired,
        storeId: PropTypes.number,
        storeImage: PropTypes.string,
        storeName: PropTypes.string,
        subcategoryId: PropTypes.number,
        subcategoryName: PropTypes.string,
        subcategorySlug: PropTypes.string,
        userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        userName: PropTypes.string.isRequired,
        userPhoto: PropTypes.string.isRequired,
        userTypeName: PropTypes.string.isRequired,
        userUsername: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default OffersListSection;
