import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { twJoin } from 'tailwind-merge';

import FlatLayout from 'components/Layout/FlatLayout';

import useModal from 'hooks/useModal';

import { stripQs } from 'lib/request';
import { sendSupixEvent } from 'lib/supix';

import { useGlobalQueries } from 'providers/GlobalQueriesProvider';
import { useUtm } from 'providers/UtmProvider';

import FeaturedOffers from './FeaturedOffers';
import OffersListSection from './OffersListSection';
import WishlistMaintenanceActionSheet from './WishlistMaintenanceActionSheet';

import LINKS from 'constants/links';

const ScrollToTopButton = dynamic(
  () => import('components/ScrollToTopButton'),
  { ssr: false }
);

const ResetPasswordModal = dynamic(
  () => import('components/ResetPasswordModal'),
  { ssr: false }
);
const AdsCarousel = dynamic(() => import('./AdsCarousel'), {
  ssr: true,
});

const GA_EVENT_CATEGORY = 'timeline';
const HIGHLIGHTS_OFFERS = 'highlights';

const HomePage = ({
  cardsAds,
  cookieIsUserLogged,
  description = null,
  pageTitle = null,
  recoveryPassword = null,
  serverFeaturedOffers,
  serverOffers,
  smallAds,
}) => {
  const { activeOffersListTab } = useGlobalQueries();
  const { showModal } = useModal();
  const router = useRouter();
  const { isUtmLoaded } = useUtm();

  useEffect(() => {
    if (!recoveryPassword) {
      return;
    }

    /**
     * When the query params of recovery password are invalid, we remove these
     * params from the URL without refresh completely the page and add a new
     * state in navigation history
     */
    if (recoveryPassword) {
      if (recoveryPassword.isValid) {
        showModal(ResetPasswordModal, recoveryPassword);
        return;
      }

      router.replace(LINKS.HOME, undefined, { shallow: true });
    }
  }, []);

  useEffect(() => {
    if (isUtmLoaded) {
      sendSupixEvent('/car', { category: 0 });
    }
  }, [isUtmLoaded]);

  const isIndexed = stripQs(router.asPath) !== LINKS.WISHLIST;

  return (
    <>
      <NextSeo
        description={description}
        nofollow={!isIndexed}
        noindex={!isIndexed}
        openGraph={{
          title: pageTitle,
          description,
        }}
        title={pageTitle}
      />
      <FlatLayout.Margin className="-mt-4 lg:-mt-14 [&_*]:font-rubik">
        <FlatLayout.Body>
          {smallAds.length > 0 && (
            <div className="col-span-4 w-full pt-4 md:col-span-16">
              <AdsCarousel
                ads={smallAds}
                className={
                  activeOffersListTab.id !== HIGHLIGHTS_OFFERS && 'mb-4'
                }
                gaEventCategory={GA_EVENT_CATEGORY}
              />
            </div>
          )}
          <div
            className={twJoin(
              'col-span-4 w-full pt-8 md:col-span-16',
              activeOffersListTab.id !== HIGHLIGHTS_OFFERS && 'hidden md:block'
            )}
            data-test-selector="featured-offers"
          >
            <FeaturedOffers
              gaEventCategory={GA_EVENT_CATEGORY}
              serverFeaturedOffers={serverFeaturedOffers}
              title="Recomendadas"
            />
          </div>
          <div
            className={twJoin(
              'mt-12 grid grid-cols-4 gap-0 gap-x-4 md:grid-cols-12 lg:mt-16',
              activeOffersListTab.id !== HIGHLIGHTS_OFFERS &&
                smallAds.length.length > 0 &&
                'pt-8 md:pt-0'
            )}
          >
            <OffersListSection
              cardsAds={cardsAds}
              cookieIsUserLogged={cookieIsUserLogged}
              gaEventCategory={GA_EVENT_CATEGORY}
              serverOffers={serverOffers}
            />
          </div>
          <ScrollToTopButton gaEventCategory={GA_EVENT_CATEGORY} />
        </FlatLayout.Body>
      </FlatLayout.Margin>
      <FlatLayout.Footer />
      <WishlistMaintenanceActionSheet />
    </>
  );
};

HomePage.propTypes = {
  cardsAds: PropTypes.arrayOf(
    PropTypes.shape({
      bgcolor: PropTypes.arrayOf(PropTypes.string),
      description: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      image: PropTypes.string.isRequired,
      isClosable: PropTypes.bool.isRequired,
      mobileImage: PropTypes.string.isRequired,
      position: PropTypes.number.isRequired,
      storeSlug: PropTypes.string,
      type: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  description: PropTypes.string,
  recoveryPassword: PropTypes.shape({
    recoveryHash: PropTypes.string.isRequired,
    userEmail: PropTypes.string.isRequired,
    isValid: PropTypes.bool.isRequired,
  }),
  serverFeaturedOffers: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.number.isRequired,
      categorySlug: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      lastActiveOffer: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      offerComments: PropTypes.number.isRequired,
      offerId: PropTypes.number.isRequired,
      offerIsHighlight: PropTypes.bool.isRequired,
      ratings: PropTypes.shape().isRequired,
      offerOldPrice: PropTypes.number,
      offerPhoto: PropTypes.string.isRequired,
      offerPrice: PropTypes.number.isRequired,
      offerPriceType: PropTypes.string.isRequired,
      offerPublished: PropTypes.string.isRequired,
      offerSlug: PropTypes.string.isRequired,
      offerStatusName: PropTypes.string.isRequired,
      offerUserVisibility: PropTypes.string.isRequired,
      offerTags: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
        })
      ),
      offerTitle: PropTypes.string.isRequired,
      pageTitle: PropTypes.string,
      storeDomain: PropTypes.string.isRequired,
      storeId: PropTypes.number,
      storeName: PropTypes.string,
      subcategoryId: PropTypes.number,
      subcategorySlug: PropTypes.string.isRequired,
      userId: PropTypes.number.isRequired,
      userLevel: PropTypes.number,
      userName: PropTypes.string.isRequired,
      userPhoto: PropTypes.string.isRequired,
      userTypeName: PropTypes.string.isRequired,
      userUsername: PropTypes.string.isRequired,
    })
  ).isRequired,
  serverOffers: PropTypes.shape({
    after: PropTypes.string.isRequired,
    before: PropTypes.number,
    offers: PropTypes.arrayOf(
      PropTypes.shape({
        categoryId: PropTypes.number,
        categoryName: PropTypes.string,
        categorySlug: PropTypes.string,
        key: PropTypes.string.isRequired,
        offerComments: PropTypes.number.isRequired,
        offerId: PropTypes.number.isRequired,
        offerIsHighlight: PropTypes.bool.isRequired,
        ratings: PropTypes.shape().isRequired,
        offerOldPrice: PropTypes.number,
        offerPhoto: PropTypes.string.isRequired,
        offerPrice: PropTypes.number.isRequired,
        offerPriceType: PropTypes.string.isRequired,
        offerPublished: PropTypes.string.isRequired,
        offerSlug: PropTypes.string.isRequired,
        offerStatusName: PropTypes.string.isRequired,
        offerUserVisibility: PropTypes.string.isRequired,
        offerTags: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
          })
        ),
        offerTitle: PropTypes.string.isRequired,
        storeDomain: PropTypes.string.isRequired,
        storeId: PropTypes.number,
        storeImage: PropTypes.string,
        storeName: PropTypes.string,
        subcategoryId: PropTypes.number,
        subcategoryName: PropTypes.string,
        subcategorySlug: PropTypes.string,
        userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        userName: PropTypes.string.isRequired,
        userPhoto: PropTypes.string.isRequired,
        userTypeName: PropTypes.string.isRequired,
        userUsername: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  smallAds: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
};

export default HomePage;
