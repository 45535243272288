import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

const Body = ({ children, className }) => (
  <main className={twMerge('flex w-full flex-col flex-nowrap', className)}>
    {children}
  </main>
);

Body.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Body;
