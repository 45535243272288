import Body from './Body';
import Footer from './Footer';
import Heading from './Heading';
import Margin from './Margin';

const FlatLayout = {
  Body,
  Footer,
  Heading,
  Margin,
};

export default FlatLayout;
